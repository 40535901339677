import { createAction } from "@reduxjs/toolkit"

export const CONFIG_ADD_UTMCHATBOT = createAction("config/add/utmchatbot")

export const CONFIG_ADD_GCLID = createAction("config/add/gclid")

export const CONFIG_ADD_MSMLID = createAction("config/add/msmlid")

export const CONFIG_ADD_SESSION_ID = createAction("config/add/SessionFeId")

export const CONFIG_ADD_QUERY_STRING_VALUES = createAction(
	"config/add/QueryStringValues"
)
